import { css } from '@emotion/react';
import { spacing } from '@prototyp/gatsby-plugin-gumball/mixins';

export const flex = css`
  display: flex;
  align-items: center;
`;

export const tableLabel = css`
  color: hsl(var(--color-textLight-7));
  font-size: var(--font-size-micro);
  text-transform: uppercase;
`;

export const textFaded = css`
  color: hsl(var(--color-textLight-7));
`;

export const detailsHeader = css`
  margin: 0 0 ${spacing(0)};
  padding-bottom: ${spacing(40)};
`;

export const detailsTags = css`
  margin: ${spacing(-5)} 0 0 ${spacing(15)};
`;

export const staffRoleTag = css`
  font-weight: var(--font-weight-regular);
  margin: 0 0 0 ${spacing(10)};
  pointer-events: none;
  text-transform: capitalize;
`;

export const deletedAtTag = css`
  color: hsl(var(--color-white-11));
  font-weight: var(--font-weight-regular);
  pointer-events: none;
  text-transform: capitalize;
  div {
    background: hsl(var(--color-borderBlack-2));
  }
`;

export const actions = css`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto;
  grid-row-gap: ${spacing(32)};
  align-items: center;
  font-weight: 700;
  margin: 0 0 ${spacing(76)};

  a {
    cursor: pointer;
    width: auto;
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const company = css`
  color: hsl(var(--color-text-1));
  text-decoration: underline;
`;

export const actionsButton = css`
  display: flex;
  justify-content: flex-start;
  line-height: 1;
  margin: 0 ${spacing(35)} 0 0;
  padding: 0;

  & > span:last-of-type {
    flex: 0;
  }

  &:hover {
    text-decoration: underline;
  }
`;

export const table = css`
  tr {
    td {
      border-bottom: 1px solid hsl(var(--color-borderUltraLight-9));
      font-size: var(--font-size-small);
      min-width: ${spacing(84)};
      padding: ${spacing(32)} 0 ${spacing(20)};

      strong {
        display: flex;
        align-content: center;
        padding: 0 ${spacing(20)} 0 0;
      }
    }
  }
`;

export const fillBlack = css`
  path {
    fill: hsl(var(--color-text-1));
  }
`;

export const deactivateFillBlack = css`
  path {
    stroke: hsl(var(--color-text-1));
  }
  circle {
    stroke: hsl(var(--color-text-1));
  }
`;
